<template>
    <div class="PlanProgress" style=" margin: -15px -15px;">
      <!--  PlanProgress 直播计划进展查询-->
      <div>
        <div style="width: 98%;margin: auto">
          <a-button @click="openModel" style="margin-top: 20px;margin-bottom: 20px" type="primary" >新增</a-button>
          <a-table
              :pagination="false"
              :columns="columns"
              :data-source="data"
              :row-key="record => record.id">
            <span slot="action" slot-scope="text, record">
              <a @click="openRest">修改</a>
            </span>
            <span slot="state" slot-scope="text, record">
              <a-switch v-model="record.state === 1?true:false" checked-children="正常" un-checked-children="关闭" @click="listSwitch(record.state,record)" default-checked />
            </span>
          </a-table>
          <!--          分页-->
          <div style="width: 100%;height: 50px;">
            <div style="float: right;margin-right: 20px;margin-top: 15px">
              <a-pagination
                  show-quick-jumper
                  :current="page_no"
                  @change="listChange"
                  :total="listCount" />
            </div>
          </div>
        </div>
      </div>
  <!--    对话框---------------------------------->
      <a-drawer
          :title="drawerTitle"
          :width="550"
          :visible="visible"
          :body-style="{ paddingBottom: '80px' }"
          @close="closeBtn"
      >
        <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item  label="所属年份" prop="year">
            <a-date-picker
                v-model="form.year"
                mode="year"
                v-decorator="['year']"
                placeholder="请输入"
                format="YYYY"
                class="input"
                :open="yearShowOne"
                @openChange="openChangeOne"
                @panelChange="panelChangeOne"/>
          </a-form-model-item>
          <a-form-model-item  label="合同名称" prop="name">
            <a-input v-model="form.person" style="width: 300px" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item  label="计划直播数量" prop="number">
            <a-input v-model="form.tel" style="width: 300px" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item  label="计划日期" prop="dates">
            <a-input v-model="form.tel" style="width: 300px" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item  label="业务负责人" prop="principal">
            <a-select style="width: 300px" placeholder="请选择" v-model="form.principal" >
              <a-select-option
                  v-for="item in principalList"
                  :value="item.value">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="closeBtn">
            取消
          </a-button>
          <a-button type="primary" @click="onSubmit">
            确认
          </a-button>
        </div>
      </a-drawer>
      <!-- 底部   -->
      <div style="height: 60px"></div>
      <div style="width:100%;height:50px;position: fixed;bottom: 50px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
        <div
            style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
          <a-button @click="returnBtn">返回上一层</a-button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {update} from "@/utils/update";
  import City from "@/assets/city.json";
  import {current_date} from "@/utils/currentDate";
  
  export default {
  name: "PlanProgress",
    created() {
      this.$store.dispatch('setManageHeaderTitle',"合作计划")
    },
    data(){
      return{
        nameTop:'',
        stateTop:'',
        stateTypeList:[
          {
            title:'全部',
            state:''
          },{
            title:'正常',
            state:'MIX_SET_TOPIC'
          },{
            title:'关闭',
            state:'VOD_SINGLE'
          },
        ],
        columns:[
          {
            title: '名称',
            dataIndex: 'title',
            key: 'title',
            width:'30%',
            scopedSlots: { customRender: 'name' },
            ellipsis: true,
          },{
            title: '直播数量计划/剩余',
            dataIndex: 'expertName',
            key: 'expertName',
            // scopedSlots: { customRender: 'expertName' },
            width:'18%',
          },{
            title: '所属年份',
            dataIndex: 'tel',
            key: 'tel',
            width:'15%',
          },{
            title: '开始时间',
            dataIndex: 'person',
            key: 'person',
            width:'15%',
          },{
            title: '结束时间',
            dataIndex: 'createdTime',
            key: 'createdTime',
            ellipsis: true,
            width:'15%',
          },{
            title: '业务负责人',
            dataIndex: 'persons',
            key: 'persons',
            width:'15%',
            scopedSlots: { customRender: 'persons' },
          },{
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width:'10%',
            scopedSlots: { customRender: 'state' },
          },{
            title: '操作',
            key: 'action',
            width:'15%',
            scopedSlots: { customRender: 'action' },
          },
        ],
        data:[
          {
            id:1,
            title:'1',
            expertName:'1',
            tel:'1',
            person:'1',
            persons:'1',
            createdTime:'1',
            state:1,
          }
        ],
        page_no:1,
        listCount:0,
        visible:false,
        labelCol: { span: 5},
        wrapperCol: { span: 14 },
        form:{
          year:current_date(),
          name:'',
          number:'',
          dates:'',
          principal:undefined,
        },
        yearShowOne:false,
        rules: {
          year: [{ required: true, message: '请选择', trigger: 'change' }],
          name: [{ required: true, message: '请输入', trigger: 'blur' }],
          number: [{ required: true, message: '请输入', trigger: 'blur' }],
          dates: [{ required: true, message: '请选择', trigger: 'blur' }],
          principal: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        drawerTitle:'新增',
        principalList:[
          {
            value:'',
            name:1111,
          }
        ]
      }
    },
    methods:{
      // 弹出日历和关闭日历的回调
      openChangeOne(status) {
        if (status) {
          this.yearShowOne = true;
        }else {
          this.yearShowOne = false;
        }
      },
      // 得到年份选择器的值
      panelChangeOne(value) {
        this.yearShowOne = false;
        this.form.year = value;
        console.log(value)
      },
      closeBtn(){
        this.visible = false
      },
      onSubmit(){
        this.visible = false
      },
      openModel(){
        this.drawerTitle = '新增'
        this.visible = true
      },
      openRest(){
        this.drawerTitle = '修改'
        this.visible = true
      },
      listSwitch(isOpen){
  
      },
      listChange(pageNo){
        this.page_no = pageNo
      },
      async uplistCover() {
        let inputDOM = this.$refs.Thumbnail.files[0];
        //判断图片大小
        if (inputDOM.size < 1048576) {
          const image = await update(inputDOM)
          if (image.code === 0) {
            this.form.coverUrl = image.data.url
            this.$message.success("上传成功")
            document.getElementById('coverFoemId').value = null;
          } else {
            this.$message.warning("上传失败")
          }
        } else {
          this.$message.warning("请上传小于1M的图片！")
        }
      },
      returnBtn(){
        window.history.back()
      },
    }
  }
  </script>
  
  <style scoped lang="scss">
  .PlanProgress {
    height: 100%;
  }
  .uploadImg{
    border: #DDDDDD 2px dashed;
    width: 120px;
    height: 120px;
    background-image: url("../../../assets/add.png");
    background-size:100% 100%;
    background-repeat:no-repeat;
    cursor: pointer;
  }
  .upload_image{
    border: #DDDDDD 2px dashed;
    width: 110px;
    min-height: 120px;
    margin-left: 20px;
    text-align: center;
  }
  </style>